import React from 'react';
import { Helmet } from 'react-helmet';

import OCX_EVENT from 'mocks/webflow/ocx-event';

import 'styles/webflow/normalize.scss';
import 'styles/webflow/webflow.scss';
import 'styles/webflow/screver-ocx-event.webflow.scss';

const OcxEvent = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>Screver OCX Event | Kraftwerk Zürich</title>
        <meta name="title" content="Screver OCX Event | Kraftwerk Zürich" />
        <meta
          name="description"
          content="We’ll share some exciting news, show off a sneak peek of innovative features, and showcase how AI shapes Voice of the Customer strategies."
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://screver.com/events/screver-ocx/"
        />
        <meta
          property="og:title"
          content="Screver OCX Event | Kraftwerk Zürich"
        />
        <meta
          property="og:description"
          content="We’ll share some exciting news, show off a sneak peek of innovative features, and showcase how AI shapes Voice of the Customer strategies."
        />
        <meta
          property="og:image"
          content="https://wp.screver.com/wp-content/uploads/2023/08/screver-ocx-meta-img.jpeg"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://screver.com/events/screver-ocx/"
        />
        <meta
          property="twitter:title"
          content="Screver OCX Event | Kraftwerk Zürich"
        />
        <meta
          property="twitter:description"
          content="We’ll share some exciting news, show off a sneak peek of innovative features, and showcase how AI shapes Voice of the Customer strategies."
        />
        <meta
          property="twitter:image"
          content="https://wp.screver.com/wp-content/uploads/2023/08/screver-ocx-meta-img.jpeg"
        />

        <meta name="theme-color" content="#191820"></meta>
      </Helmet>
      <div
        className="webflow webflow-ocx-event"
        dangerouslySetInnerHTML={{ __html: OCX_EVENT }}
      ></div>
    </>
  );
};

export default OcxEvent;
